import { createSelector } from "reselect";

const extendPeriodSelector = ({ extendPeriod }) => extendPeriod;

export const getExtendPeriodTableColumns = createSelector(
  [extendPeriodSelector],
  ({ extendPeriodTableColumns }) => extendPeriodTableColumns
);

export const getExtendPeriods = createSelector(
  [extendPeriodSelector],
  ({ extendPeriods }) => extendPeriods
);
export const getExtendPeriod = createSelector(
  [extendPeriodSelector],
  ({ extendPeriod }) => extendPeriod
);
export const getCreateExtendPeriod = createSelector(
  [extendPeriodSelector],
  ({ createExtendPeriod }) => createExtendPeriod
);
export const getCustomExtendPeriod = createSelector(
  [extendPeriodSelector],
  ({ customExtendPeriod }) => customExtendPeriod
);

export const getIsExtendPeriodsHasMore = createSelector(
  [extendPeriodSelector],
  ({ isExtendPeriodsHasMore }) => isExtendPeriodsHasMore
);

export const getFetchExtendPeriodsSearch = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsSearch }) => fetchExtendPeriodsSearch
);
export const getFetchExtendPeriodsSort = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsSort }) => fetchExtendPeriodsSort
);
export const getFetchExtendPeriodsKeyBy = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsKeyBy }) => fetchExtendPeriodsKeyBy
);
export const getFetchExtendPeriodsPage = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsPage }) => fetchExtendPeriodsPage
);
export const getFetchExtendPeriodsPerPage = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsPerPage }) => fetchExtendPeriodsPerPage
);
export const getFetchExtendPeriodsIncludes = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsIncludes }) => fetchExtendPeriodsIncludes
);
export const getFetchExtendPeriodsFilterMarketId = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterMarketId }) => fetchExtendPeriodsFilterMarketId
);
export const getFetchExtendPeriodsFilterBranchId = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterBranchId }) => fetchExtendPeriodsFilterBranchId
);
export const getFetchExtendPeriodsFilterStatus = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterStatus }) => fetchExtendPeriodsFilterStatus
);
export const getFetchExtendPeriodsFilterSubscriptionKey = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterSubscriptionKey }) =>
    fetchExtendPeriodsFilterSubscriptionKey
);
export const getFetchExtendPeriodsFilterSubscriptionOptionKey = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterSubscriptionOptionKey }) =>
    fetchExtendPeriodsFilterSubscriptionOptionKey
);
export const getFetchExtendPeriodsFilterCreatedBy = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFilterCreatedBy }) => fetchExtendPeriodsFilterCreatedBy
);
export const getFetchExtendPeriodsLoading = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsLoading }) => fetchExtendPeriodsLoading
);
export const getFetchExtendPeriodsSuccess = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsSuccess }) => fetchExtendPeriodsSuccess
);
export const getFetchExtendPeriodsFailed = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodsFailed }) => fetchExtendPeriodsFailed
);

export const getFetchExtendPeriodLoading = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodLoading }) => fetchExtendPeriodLoading
);
export const getFetchExtendPeriodSuccess = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodSuccess }) => fetchExtendPeriodSuccess
);
export const getFetchExtendPeriodFailed = createSelector(
  [extendPeriodSelector],
  ({ fetchExtendPeriodFailed }) => fetchExtendPeriodFailed
);

export const getCreateExtendPeriodLoading = createSelector(
  [extendPeriodSelector],
  ({ createExtendPeriodLoading }) => createExtendPeriodLoading
);
export const getCreateExtendPeriodSuccess = createSelector(
  [extendPeriodSelector],
  ({ createExtendPeriodSuccess }) => createExtendPeriodSuccess
);
export const getCreateExtendPeriodFailed = createSelector(
  [extendPeriodSelector],
  ({ createExtendPeriodFailed }) => createExtendPeriodFailed
);

export const getCustomExtendPeriodLoading = createSelector(
  [extendPeriodSelector],
  ({ customExtendPeriodLoading }) => customExtendPeriodLoading
);
export const getCustomExtendPeriodSuccess = createSelector(
  [extendPeriodSelector],
  ({ customExtendPeriodSuccess }) => customExtendPeriodSuccess
);
export const getCustomExtendPeriodFailed = createSelector(
  [extendPeriodSelector],
  ({ customExtendPeriodFailed }) => customExtendPeriodFailed
);

export const getStopExtendPeriodLoading = createSelector(
  [extendPeriodSelector],
  ({ stopExtendPeriodLoading }) => stopExtendPeriodLoading
);
export const getStopExtendPeriodSuccess = createSelector(
  [extendPeriodSelector],
  ({ stopExtendPeriodSuccess }) => stopExtendPeriodSuccess
);
export const getStopExtendPeriodFailed = createSelector(
  [extendPeriodSelector],
  ({ stopExtendPeriodFailed }) => stopExtendPeriodFailed
);

export const getIsFetchExtendPeriodsHitted = createSelector(
  [extendPeriodSelector],
  ({ isFetchExtendPeriodsHitted }) => isFetchExtendPeriodsHitted
);
export const getIsFetchExtendPeriodHitted = createSelector(
  [extendPeriodSelector],
  ({ isFetchExtendPeriodHitted }) => isFetchExtendPeriodHitted
);
export const getIsCreateExtendPeriodHitted = createSelector(
  [extendPeriodSelector],
  ({ isCreateExtendPeriodHitted }) => isCreateExtendPeriodHitted
);
export const getIsCustomExtendPeriodHitted = createSelector(
  [extendPeriodSelector],
  ({ isCustomExtendPeriodHitted }) => isCustomExtendPeriodHitted
);
export const getIsStopExtendPeriodHitted = createSelector(
  [extendPeriodSelector],
  ({ isStopExtendPeriodHitted }) => isStopExtendPeriodHitted
);
