const SELECT_COMMON_ACTION_TYPES = {
  RESET_SELECT_COMMON_REDUCER: "select-common/RESET_SELECT_COMMON_REDUCER",

  SET_ORDER_STATUSES: "select-common/SET_ORDER_STATUSES",

  SET_SUBSCRIPTION_PLANS: "select-common/SET_SUBSCRIPTION_PLANS",
  SET_SUBSCRIPTION_PERIODS: "select-common/SET_SUBSCRIPTION_PERIODS",
  SET_SUBSCRIPTION_STATUSES: "select-common/SET_SUBSCRIPTION_STATUSES",

  SET_REPORT_EXPORT_TYPES: "select-common/SET_REPORT_EXPORT_TYPES",
};

export default SELECT_COMMON_ACTION_TYPES;
